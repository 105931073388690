import config from 'config';

import { AbstractService, AbstractServiceConfig, LoggerParameter } from 'services/AbstractService';
import { ServiceError } from 'services/errors';
import { AxiosError } from 'axios';

export class MsxService extends AbstractService implements VP.MTT.MsxService.Services.IMsxService {
    apiVersion: number;

    constructor({ version, ...rest }: AbstractServiceConfig, logger: LoggerParameter) {
        super(rest, logger);
        this.apiVersion = version;
    }

    /**
     * Request Default Quantities from MSX
     * @param productKey
     * @param version
     * @param tenant
     * @param locales
     */
    public async getDefaultQuantities(
        tenant: string,
        locales: i18n.Locale[],
        productKey?: string,
        version?: number,
    ): Promise<VP.MTT.Models.MsxService.DefaultQuantitiesResult | null> {
        if (!productKey || !version) {
            throw new Error(
                `Unable to call getDefaultQuantities. productKey, and version are required `,
            );
        }

        const url = `v2/tenant/${tenant}/productKey/${productKey}/version/${version}/quantities`;

        try {
            const response = await this.api.get<VP.MTT.Models.MsxService.DefaultQuantitiesResult>(url, {
                params: {
                    lower: true,
                    cultures: locales,
                    requestor: config.appName,
                },
                paramsSerializer: {
                    indexes: null,
                },
            });

            return response.data;
        } catch (e) {
            this.logger.error(new ServiceError({
                url,
                message: 'Bad response from msx service',
                response: (e as AxiosError).response,
            }, e as Error));
            return null;
        }
    }

    /**
     * Request Iconography from MSX
     * @param productKey
     * @param version
     * @param tenant
     * @param locale
     * @param imageTransformations
     * @param iconTransformations
     */
    public async getIconography(
        productKey: string,
        version: number,
        tenant: string,
        locale: i18n.Locale,
        imageTransformations?: string | undefined,
        iconTransformations?: string | undefined,
    ): Promise<VP.MTT.Models.MsxService.IconographyResult | null> {
        const url = `v2/tenant/${tenant}/productKey/${productKey}/version/${version}/experienceandordering/attributesandvalues`;

        try {
            const response = await this.api.get<VP.MTT.Models.MsxService.IconographyResult>(url, {
                params: {
                    requestor: config.appName,
                    cultures: locale,
                    imageTransformations: imageTransformations || 'width=200,height=200',
                    iconTransformations: iconTransformations || 'width=32,height=32',
                },
            });

            return response.data;
        } catch (e) {
            this.logger.error(new ServiceError({
                url,
                message: 'Bad response from msx service',
                response: (e as AxiosError).response,
            }, e as Error));
            return null;
        }
    }

    public async getMsxData(
        productKey: string,
        locale: i18n.Locale,
    ): Promise<VP.MTT.Models.MsxService.GetMsxDataResult> {
        const requestor = config.appName;
        const url = `v1/tenant/vistaprint/productKey/${productKey}/mpvs`;

        const response = await this.api.get<VP.MTT.Models.MsxService.GetMsxDataResult>(url, {
            params: {
                requestor,
                cultures: locale,
            },
        });

        return response.data;
    }
}
