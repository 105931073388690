import { HTMLProps } from 'react';

export const TileContent = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { children, ...rest } = props;

    return (
        <div {...rest} className="tile-content">
            {children}
        </div>
    );
};

TileContent.displayName = 'TileContent';
