import config from 'config';
import VistaprintAuth, { Identity, WebAuth } from '@vp/auth';

export type IdentityState = Identity & {
    canonicalId: string;
    QuantumMetricUserID: string | null;
    signIn: (additionalReturnUrlQueryParameters?: Record<string, unknown>[]) => void;
};

export function buildIdentityState(webAuth: WebAuth): IdentityState | null {
    const identity = webAuth.getIdentity();
    const canonicalId = webAuth.getCanonicalId();
    const signIn = (additionalReturnUrlQueryParameters: Record<string, unknown>[]): void => webAuth.signIn(
        additionalReturnUrlQueryParameters,
    );

    return (canonicalId && identity.profile)
        ? {
            canonicalId,
            QuantumMetricUserID: window.localStorage.getItem('QuantumMetricUserID'),
            signIn,
            ...identity,
        } as IdentityState
        : null;
}

export const init = (
    locale: i18n.Locale,
    signoutRedirectUrl: string,
): Promise<void> => VistaprintAuth.init({
    culture: locale,
    options: {
        redirectAfterSignOutCallback: (): string => signoutRedirectUrl,
        // clientID should only be used for non-production environments
        clientID: config.client.deployment !== 'production' ? config.client.auth.clientID : undefined,
        redirectUriOrigin: `${window.location.origin}${config.client.auth.authCallbackPath}`,
        requireSession: true,
    },
});

export const webAuth = new VistaprintAuth.WebAuth();
