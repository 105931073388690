/* eslint-disable react/no-danger */
import { HTMLProps } from 'react';

import { SINGLE_SPACE_ENCODED } from 'client/constants';

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    lines: number;
}

export const SpacingBlock = (props: PropTypes): JSX.Element => {
    const { lines, ...rest } = props;

    return (
        <div {...rest} className="price-block">
            {/* eslint-disable-next-line react/no-danger */}
            <span className="pricing-display-spacing-block" dangerouslySetInnerHTML={{ __html: SINGLE_SPACE_ENCODED }} />
            {lines > 1 && (
            // eslint-disable-next-line react/no-danger
            <span className="pricing-display-spacing-block" dangerouslySetInnerHTML={{ __html: SINGLE_SPACE_ENCODED }} />
            )}
        </div>
    );
};

SpacingBlock.displayName = 'SpacingBlock';
