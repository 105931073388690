import { stringifyWorkEntityProperties } from 'client/utils/parseWorkEntity';
import { WesService } from 'services/WesService';

export const createFavorite = async (
    {
        tenant,
        newFavorite,
        ownerId,
        accessToken,
    }: VP.DTT.Mutations.WorkEntityService.CreateMutationVariables,
): Promise<string> => WesService.addFavorite(tenant, stringifyWorkEntityProperties(newFavorite), ownerId, accessToken);
