import '@vp/pricing-context-module';
import pricingContextModuleHelpers from '@vp/pricing-context-module/helpers';
import { useSetRecoilState } from 'recoil';

import { pricingContextAtom } from 'client/atoms/pricingContext';
import { useEffect } from 'react';

export const useInitPricingContext = (market: string, merchantId: string): void => {
    const setPricingContextModule = useSetRecoilState(pricingContextAtom);

    useEffect(() => {
        const config: PricingContextModule.Configuration = {
            isDevelopmentMode: process.env.NODE_ENV === 'development',
            market,
            merchantId,
        };

        // Set the pricing context "class" instead of an object since the pricing context
        // doesn't include some of the fields we'll need (like coupon code or vat)
        pricingContextModuleHelpers.initializePricingContext(config, () => {
            setPricingContextModule({ ...window.document.pcxtV3 });
        });

        // This is used to trigger any consumers of this hook to re-render since the reference
        // to the actual pricing context "class" is returned
        pricingContextModuleHelpers.executeAfterPricingContextEvent(
            pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT,
            () => setPricingContextModule({ ...window.document.pcxtV3 }),
            true,
        );

        pricingContextModuleHelpers.executeAfterPricingContextEvent(
            pricingContextModuleHelpers.EVENTS.DATE_TIME_CHANGE_EVENT,
            () => setPricingContextModule({ ...window.document.pcxtV3 }),
            true,
        );

        pricingContextModuleHelpers.executeAfterPricingContextEvent(
            pricingContextModuleHelpers.EVENTS.CONTEXT_CHANGE_EVENT,
            () => setPricingContextModule({ ...window.document.pcxtV3 }),
            true,
        );

        pricingContextModuleHelpers.executeAfterPricingContextEvent(
            pricingContextModuleHelpers.EVENTS.COUPON_CHANGE_EVENT,
            () => setPricingContextModule({ ...window.document.pcxtV3 }),
            true,
        );
    }, [market, merchantId, setPricingContextModule]);
};
