import {
    useState,
    useEffect,
} from 'react';
import { UseColorSwatchesParams } from '~/client/components/common/ColorSwatchPicker/hooks/interface';

const MAX_COLOR_SWATCHES_TO_SHOW = 4;
const MOBILE_MAX_COLOR_SWATCHES_TO_SHOW = 3;
const MERCH_MOBILE_MAX_COLOR_SWATCHES_TO_SHOW = 2;

export const useColorSwatchesParameters = (
    collapse: boolean,
    colorSwatches: Gallery.ContentQuery.ColorSwatch[],
    shouldShowMerchModules?: boolean,
): UseColorSwatchesParams => {
    const mobileMaxColorSwatchesToShow = shouldShowMerchModules
        ? MERCH_MOBILE_MAX_COLOR_SWATCHES_TO_SHOW
        : MOBILE_MAX_COLOR_SWATCHES_TO_SHOW;

    const [shouldShowMore, setShouldShowMore] = useState(!collapse);
    const visibleSwatches = shouldShowMore ? colorSwatches : colorSwatches.slice(0, MAX_COLOR_SWATCHES_TO_SHOW);

    const shouldShowMoreButton = collapse && colorSwatches.length > MAX_COLOR_SWATCHES_TO_SHOW;

    const shouldShowMoreMobile = collapse && colorSwatches.length > mobileMaxColorSwatchesToShow;
    const shouldHideTwoLastItems = shouldShowMerchModules && colorSwatches.length > MOBILE_MAX_COLOR_SWATCHES_TO_SHOW;
    const shouldHideLastItem = shouldShowMerchModules && colorSwatches.length < MERCH_MOBILE_MAX_COLOR_SWATCHES_TO_SHOW;

    const colorSwatchesLength = shouldShowMerchModules && colorSwatches.length < MERCH_MOBILE_MAX_COLOR_SWATCHES_TO_SHOW
        ? colorSwatches.length - mobileMaxColorSwatchesToShow
        : colorSwatches.length - MAX_COLOR_SWATCHES_TO_SHOW;

    useEffect(() => {
        setShouldShowMore(!collapse);
    }, [collapse]);

    return {
        shouldShowMore,
        visibleSwatches,
        shouldShowMoreMobile,
        shouldHideTwoLastItems,
        shouldHideLastItem,
        colorSwatchesLength,
        setShouldShowMore,
        mobileMaxColorSwatchesToShow,
        shouldShowMoreButton,
    };
};
