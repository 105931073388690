import {
    Typography, Button, Link, LinkProps,
} from '@vp/swan';
import { LocaleContext } from 'client/contexts/LocaleContext';
import { useTranslations } from 'client/hooks/useTranslations';
import classnames from 'classnames';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryCareCalloutDetails, QUERY_KEY as CARE_CALLOUT_QUERY_KEY } from 'client/queries/queryCareCalloutDetails';

declare const PUBLIC_PATH: string;

interface PropTypes {
    resetErrorBoundary?: () => void,
}

const GHOST_ICON = `${PUBLIC_PATH}images/404-ghost.svg`;

export const NotFound = (props: PropTypes): JSX.Element => {
    const { resetErrorBoundary } = props;
    const localize = useTranslations();
    const locale = useContext(LocaleContext);
    const retryLink = `/${locale}/f`;

    const { data: careData } = useQuery(
        [CARE_CALLOUT_QUERY_KEY, { locale }],
        queryCareCalloutDetails,
        {
            enabled: !!locale,
            retry: 1,
        },
    );

    return (
        <>
            <img
                alt=""
                className={classnames('not-found-icon')}
                src={GHOST_ICON}
            />
            <Typography className="error-help" fontSize="standard" fontWeight="normal" textAlign="center">
                {localize(`NotFoundTitle`)}
            </Typography>
            <div className="retry-button">
                <Button
                    render={(linkProps: LinkProps): JSX.Element => (
                        <Link
                            className={linkProps.className}
                            href={retryLink}
                            onClick={resetErrorBoundary}
                        >
                            {linkProps.children}
                        </Link>
                    )}
                    skin="primary"
                >
                    {localize(`TryAgainCTA`)}
                </Button>
            </div>
            {careData && careData.carePhone && (
                <section aria-label={localize('CareRegionLabel')} className="care-callout">
                    <Typography className="care-callout-text" component="p">
                        <strong>
                            {careData.translations.gallery.careTitle}
                                &nbsp;
                        </strong>
                        {(careData.translations.gallery.careSubTitle).replace('<phone />', careData.carePhone.phoneNumber)}
                            &nbsp;-- &nbsp;
                        {careData.carePhone.shortFormatSchedule.map((hours) => `${hours} `)}
                    </Typography>
                </section>
            )}
        </>
    );
};

NotFound.displayName = 'NotFound';
