/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRecoilValue } from 'recoil';
import { Typography } from '@vp/swan';

import { PricingDisplay } from 'client/components/common/PricingDisplay/PricingDisplay';
import { pricingContextAtom } from 'client/atoms/pricingContext';
import { useQuery } from '@tanstack/react-query';
import { queryPricingText, QUERY_KEY as TEXT_QUERY_KEY } from 'client/queries/queryPricingText';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { useBatchPricing } from 'client/hooks/useBatchPricing';
import { memo } from 'react';
import { getPricingPresentationType } from '~/client/utils/getPricingPresentationType';

interface PropTypes extends React.ComponentProps<typeof Typography> {
    productOptionsByProductKey: Gallery.Product.ProductOptionsGroupsByProductKey | null;
    productOptionsHash?: string | null;
    selectedProductOptions?: Gallery.ContentQuery.ProductOptions;
    fullProductOptions?: Gallery.ContentQuery.ProductOptions;
    hideVatMessage?: boolean;
    hideShippingMessageInVatMessage?: boolean;
    pricingPresentationType?: string | null;
    pricingPresentationHTMLModifier?: (html: string) => string;
    productKey?: string;
    productVersion?: number;
    query?: Partial<Gallery.Pricing.QueryKeyParameters>;
    lines?: number;
    differentialPrice?: boolean;
    locale: i18n.Locale;
    market: string;
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>;
    className?: string;
}

export const ContextualBatchPricingDisplay = memo((props: PropTypes): JSX.Element => {
    const {
        productOptionsByProductKey,
        productOptionsHash: productOptionsHashProp,
        hideVatMessage,
        hideShippingMessageInVatMessage,
        pricingPresentationType,
        productKey,
        productVersion,
        lines,
        differentialPrice,
        selectedProductOptions,
        fullProductOptions,
        locale,
        market,
        quantity,
        pricingPresentationHTMLModifier = (html) => (html),
        className,
        ...rest
    } = props;

    const pricingContext = useRecoilValue(pricingContextAtom);
    const retrievedPricingContext = pricingContext?.getPricingContext();

    const resolvedPricingPresentation = differentialPrice && pricingPresentationType
        ? getPricingPresentationType(pricingPresentationType)
        : pricingPresentationType;

    const { differentialPriceData, productQuantity } = useBatchPricing({
        productOptionsByProductKey,
        productKey,
        productVersion,
        differentialPrice,
        fullProductOptions,
        locale,
        market,
        quantity,
        pricingContext,
        retrievedPricingContext,
    });

    const enabled = !!differentialPriceData
        && !!productKey
        && !!productVersion
        && !!productQuantity;

    const {
        isLoading, isError, data: formattedPrices,
    } = useQuery(
        [TEXT_QUERY_KEY, {
            pricingResponse: differentialPriceData || undefined,
            quantity: productQuantity,
            locale,
            vatInc: retrievedPricingContext?.vatInclusive || false,
            hideVatMessage: hideVatMessage || differentialPrice, // Always hide VAT in differential
            pricingPresentationType: resolvedPricingPresentation || '',
            hideShippingMessageInVatMessage: hideShippingMessageInVatMessage || false,
        }],
        queryPricingText,
        {
            enabled,
        },
    );

    const productOptionsHash = productOptionsHashProp || getProductOptionsHash(selectedProductOptions || {});

    return (
        <PricingDisplay
            className={className}
            isError={isError}
            isLoading={isLoading}
            lines={lines}
            pricingHtml={(productOptionsHash && formattedPrices)
                ? pricingPresentationHTMLModifier(formattedPrices[productOptionsHash])
                : undefined}
            pricingPresentationType={resolvedPricingPresentation}
            {...rest}
        />
    );
});

ContextualBatchPricingDisplay.displayName = 'ContextualBatchPricingDisplay';
