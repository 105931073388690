/* eslint-disable react/no-danger */
import classnames from 'classnames';
import equal from 'fast-deep-equal/es6/index';
import { memo, useEffect } from 'react';
import { Typography } from '@vp/swan';
import DOMPurify from 'isomorphic-dompurify';

import { PRICING_PRESENTATION_TYPES } from 'client/constants';
import { SpacingBlock } from 'client/components/common/PricingDisplay/SpacingBlock';
import { Skeleton } from 'client/components/common/Skeleton';

const LINE_HEIGHT_MULTIPLIER = 1.5;

interface PropTypes extends React.ComponentProps<typeof Typography> {
    pricingPresentationType?: string | null;
    isLoading: boolean;
    isError: boolean;
    pricingHtml?: string;
    lines?: number;
}

export const PricingDisplay = memo((props: PropTypes): JSX.Element => {
    const {
        className,
        pricingPresentationType,
        isLoading,
        isError,
        pricingHtml,
        lines = pricingPresentationType === PRICING_PRESENTATION_TYPES.RAW_PRICE ? 1 : 2,
        ...rest
    } = props;

    useEffect(() => {
        document.documentElement.style.setProperty('--gallery-price-block-min-height', `${lines * LINE_HEIGHT_MULTIPLIER}rem`);
    }, [lines]);

    const sanitizedPricingHtml = pricingHtml ? DOMPurify.sanitize(pricingHtml) : '';

    return (
        <span className={classnames('pricing-display', pricingPresentationType, className)}>
            {isLoading && (
                <span className="price-block">
                    <Skeleton />
                    { lines > 1 && (<Skeleton />)}
                </span>
            )}
            {(!isLoading && !isError && pricingHtml) && (
                <Typography
                    className="price-block"
                    dangerouslySetInnerHTML={{ __html: sanitizedPricingHtml }}
                    {...rest}
                    component="span"
                />
            )}
            {(!isLoading && !isError && !pricingHtml) && (<SpacingBlock lines={lines} />)}
            {(!isLoading && isError) && (<SpacingBlock lines={lines} />)}
        </span>
    );
}, equal);

PricingDisplay.displayName = 'PricingDisplay';
