import {
    Typography, Button, Link, LinkProps,
} from '@vp/swan';
import DOMPurify from 'isomorphic-dompurify';
import { LocaleContext } from 'client/contexts/LocaleContext';
import { useTranslations } from 'client/hooks/useTranslations';
import { buildFullyQualifiedVistaprintUrl } from 'client/utils/vistaprintUrlBuilder';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppError } from 'shared/errors/AppError';

interface LocationState {
    guid?: string;
    stack?: string;
}

interface PropTypes {
    error?: AppError | Error,
    resetErrorBoundary?: () => void,
    errorSubheader: string,
    retryLink?: string,
}

export const ErrorDisplay = (props: PropTypes): JSX.Element => {
    const {
        error, resetErrorBoundary, errorSubheader, retryLink,
    } = props;
    const location = useLocation();
    const localize = useTranslations();
    const locale = useContext(LocaleContext);
    const homepageLink = buildFullyQualifiedVistaprintUrl({ path: '', locale });
    const allProductsLink = buildFullyQualifiedVistaprintUrl({ path: '/all-products', locale });
    const helpMessage = `${localize(`ErrorMessage1`)} <a href=${homepageLink} skin="standard">${localize('ErrorMessage2')} </a> ${localize(`ErrorMessage3`)} <a href=${allProductsLink} skin="standard">${localize('ErrorMessage4')} </a>.`;
    const locationState = location.state as LocationState;
    const params = new URLSearchParams(location.search);

    const sanitizedHelpMessage = DOMPurify.sanitize(helpMessage);

    return (
        <>
            <Typography className="error-header" fontSize="x4large" fontWeight="normal" textAlign="center">
                {localize(`ErrorHeader`)}
            </Typography>
            <Typography className="error-subheader" fontWeight="normal" textAlign="center">
                {errorSubheader}
            </Typography>
            <Typography
                className="error-help"
                dangerouslySetInnerHTML={{ __html: sanitizedHelpMessage }}
                fontSize="standard"
                fontWeight="normal"
                textAlign="center"
            />
            {retryLink && (
                <div className="retry-button">
                    <Button
                        render={(linkProps: LinkProps): JSX.Element => (
                            <Link
                                className={linkProps.className}
                                href={retryLink}
                                onClick={resetErrorBoundary}
                            >
                                {linkProps.children}
                            </Link>
                        )}
                        skin="primary"
                    >
                        {localize(`TryAgainCTA`)}
                    </Button>
                </div>
            )}
            <Typography className="error-guid" fontSize="standard" fontWeight="normal" textAlign="center" textColor="subtle">
                {error && ('guid' in error) ? error.guid : (locationState?.guid ?? params.get('guid'))}
            </Typography>
            {process.env.NODE_ENV !== 'production' && (
                <Typography className="stack-trace" fontSize="standard" fontWeight="normal" textAlign="center">
                    <pre>
                        { error?.stack ?? locationState?.stack}
                    </pre>
                </Typography>
            )}
        </>
    );
};

ErrorDisplay.displayName = 'ErrorDisplay';
