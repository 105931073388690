import classnames from 'classnames';
import { ComponentProps, memo } from 'react';
import { Typography } from '@vp/swan';

export const ComparativeNameDisplay = memo((props: ComponentProps<typeof Typography>): JSX.Element => {
    const { className, children, ...rest } = props;

    return (
        <Typography
            className={classnames('comparative-name', className)}
            fontSkin="title-item"
            fontWeight="bold"
            {...rest}
        >
            {children}
        </Typography>
    );
});

ComparativeNameDisplay.displayName = 'ComparativeNameDisplay';
