import { PRICING_STALE_TIME, PRICING_CACHE_TIME } from 'client/constants';
import { useBatchFullProductOptionPricing } from 'client/hooks/useBatchFullProductOptionPricing';
import { useQuantities } from 'client/hooks/useQuantities';

export interface UseBatchPricingInput {
    productOptionsByProductKey: Gallery.Product.ProductOptionsGroupsByProductKey | null;
    fullProductOptions?: Gallery.ContentQuery.ProductOptions;
    productKey?: string;
    differentialPrice?: boolean;
    productVersion?: number;
    locale: i18n.Locale;
    market: string;
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>;
    pricingContext: PricingContextModule.PcxtV3 | null;
    retrievedPricingContext: PricingContextModule.PricingContext | undefined;
}

export const useBatchPricing = (params: UseBatchPricingInput):
VP.PCT.Models.ProductCatalogPricingService.PricingDataResult => {
    const {
        productOptionsByProductKey,
        fullProductOptions,
        productKey,
        differentialPrice,
        productVersion,
        locale,
        market,
        quantity,
        pricingContext,
        retrievedPricingContext,
    } = params;

    const { productQuantity } = useQuantities(
        {
            productKey,
            productVersion,
            locale,
            quantity,
        },
    );

    // Fetch the Differential Price once we have quantities + pricing context
    const { data: differentialPriceData } = useBatchFullProductOptionPricing(
        {
            market,
            productKey,
            quantity: productQuantity,
            productVersion,
            choiceGroups: (!!productOptionsByProductKey && !!productKey
                && productOptionsByProductKey[productKey]) || {},
            pricingContext: pricingContext?.getEncodedContextString(),
            effectiveDateTime: retrievedPricingContext?.effectiveDateTime,
            couponCode: pricingContext?.getCouponCode(),
            customerGroups: retrievedPricingContext?.customerGroups,
            selections: differentialPrice ? fullProductOptions : undefined,
        },
        fullProductOptions,
        {
            enabled: !!productQuantity && !!productKey && !!productVersion,
            staleTime: PRICING_STALE_TIME,
            cacheTime: PRICING_CACHE_TIME,
        },
    );

    return { differentialPriceData, productQuantity };
};
