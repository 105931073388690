import config from 'config';
import { AbstractService } from 'services/AbstractService';
import { getGalleryContextFromQueryString } from 'shared/url';
import type { ParameterizedContext, Request as KoaRequest } from 'koa';
import { EXPERIENCE_TYPE } from '~/shared/constants';

export class GalleryUrlService extends AbstractService implements Services.IGalleryUrlService {
    /**
     * Parses a gallery url to get the gallery context
     */
    // eslint-disable-next-line class-methods-use-this
    public async getContextFromUrl(
        galleryName: string,
        locale: i18n.Locale,
        designId: string,
        url: string,
    ): Promise<Gallery.Models.Url.Context> {
        return Promise.resolve({
            locale: locale.toLowerCase() as i18n.Locale,
            id: galleryName,
            designId,
            ...getGalleryContextFromQueryString(url),
        });
    }

    // This function takes in a URL and a Top Level Domain
    // then determines if we are in the production environment
    // and replaces the hostname with the correct domain to work around cloudflare
    public static resolveUrl(
        originOrUrl: string | URL,
        locale: string,
        path?: string,
    ): URL {
        if (typeof originOrUrl === 'string' && !path) {
            throw new Error(`Found string "origin" (${origin}), but "path"is empty`);
        }

        let resolvedUrl;

        if (typeof originOrUrl === 'string') {
            const canonicalOrigin = originOrUrl.startsWith('http://') || originOrUrl.startsWith('https://')
                ? originOrUrl
                : `http://${originOrUrl}`;

            resolvedUrl = new URL(path as string, canonicalOrigin);
        } else {
            resolvedUrl = originOrUrl;
        }

        if (process.env.DEPLOYMENT === 'production') {
            resolvedUrl.protocol = 'https';
            resolvedUrl.hostname = `gallery.vistaprint.${GalleryUrlService.resolveTopLevelDomain(locale)}`;
            resolvedUrl.port = '';
        }

        if (path) {
            resolvedUrl.pathname = path;
        }

        return resolvedUrl;
    }

    // TODO: Nice to have - use context and return correct url without optional parameters
    public static getCurrentUrl(
        origin: string,
        experience: EXPERIENCE_TYPE,
        canonicalUrl: string | undefined,
    ): URL {
        const isNotGallery = experience === EXPERIENCE_TYPE.TLP || experience === EXPERIENCE_TYPE.PRODUCT_PAGE;
        const resolvedUrl = new URL(isNotGallery && canonicalUrl
            ? canonicalUrl
            : origin);

        return resolvedUrl;
    }

    public static resolveTopLevelDomain(locale: string): string {
        return config.client.vistaprintTlds[locale.toLowerCase()];
    }

    public static buildHomepageUrl(locale: string): string {
        const culturePrefixSetting = config.client.culturePrefix[locale.toLowerCase()];

        let url = config.client.vistaprintRoot[locale];

        if (culturePrefixSetting) {
            url += `/${culturePrefixSetting}`;
        }

        return url;
    }

    // eslint-disable-next-line class-methods-use-this
    public resolveHostHeader(request: Request | KoaRequest): string {
        if ((request as Request).headers && 'get' in (request as Request).headers) {
            return (request as Request).headers.get('X-Forwarded-Host')
                || (request as Request).headers.get('x-forwarded-host')
                || (request as Request).headers.get('Host')
                || (request as Request).headers.get('Origin')
                || '';
        }

        if ((request as KoaRequest).header) {
            return (request as KoaRequest).header['X-Forwarded-Host'] as string
                || (request as KoaRequest).header['x-forwarded-host'] as string
                || (request as KoaRequest).header.host
                || (request as KoaRequest).header.origin
                || '';
        }

        if ((request as KoaRequest)?.req?.headers) {
            const headers = (request as KoaRequest)?.req?.headers;

            return headers['X-Forwarded-Host'] as string
                || headers['x-forwarded-host'] as string
                || headers.host
                || headers.origin
                || '';
        }

        return '';
    }

    public static resolveCtxUrl(ctx: ParameterizedContext): string {
        if (ctx.request && ctx.request.originalUrl) {
            return ctx.request.originalUrl;
        }

        if (ctx.request && ctx.request.url) {
            return ctx.request.url;
        }

        if (ctx.url) {
            return ctx.url;
        }

        if (ctx.request && ctx.request.ctx && ctx.request.ctx.url) {
            return ctx.request.ctx.url;
        }

        return '';
    }
}
