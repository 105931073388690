import { PRICING_PRESENTATION_TYPES } from '~/client/constants';

export const getPricingPresentationType = (pricingPresentationType: string): PRICING_PRESENTATION_TYPES => {
    switch (pricingPresentationType) {
        case PRICING_PRESENTATION_TYPES.AS_LOW_AS_PRICE_PER_PIECE:
        case PRICING_PRESENTATION_TYPES.PRICE_PER_UNIT:
            return PRICING_PRESENTATION_TYPES.DIFFERENTIAL_PRICE_PER_UNIT;
        case PRICING_PRESENTATION_TYPES.QTY_FROM_PRICE:
        case PRICING_PRESENTATION_TYPES.RAW_PRICE:
        default:
            return PRICING_PRESENTATION_TYPES.QTY_DIFFERENTIAL_PRICE;
    }
};
