import config from 'config';
import { v4 as uuid } from 'uuid';
import {
    consoleTransporter,
    rollbarTransporter,
    Logger,
    LogObject,
    LoggerContext,
    Transport,
    TransformResult,
} from '@vp/js-logger';
import { Configuration } from 'rollbar';

// eslint-disable-next-line prefer-destructuring
const ROLLBAR_SOURCEMAP_HOSTNAME = process.env.ROLLBAR_SOURCEMAP_HOSTNAME;
const TRACE_FILENAME_REGEXP = new RegExp('https?://[^/]+(.*)');

const environment = process.env.NODE_ENV || 'production';
// TODO use vp session from browser
const sessionKey = uuid();

type RollbarTransformPayload = Record<string, unknown> & {
    body: {
        trace: {
            frames: [{ filename: string }];
        };
    };
};

/**
 * Because we have multiple second-level domains (.com, .ie, .fr, etc.) and
 * need to match versioned sourcemaps against the minified source files
 * correctly, we have to replace the slc domains with a universal one that
 * we upload the sourcemaps under. Per Rollbar's docs, leverage the transform
 * configuration option to convert all filenames in the trace to the
 * universal domain.
 *
 * https://docs.rollbar.com/docs/source-maps/#section-using-source-maps-on-many-domains
 *
 * @param payload
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function rollbarTransform(payload: object, _item: object): void {
    const { trace } = (payload as RollbarTransformPayload).body;

    if (trace && trace.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
            const { filename } = trace.frames[i];

            if (filename) {
                const match = TRACE_FILENAME_REGEXP.exec(filename);

                if (match !== null && !!match[1]) {
                    trace.frames[i].filename = `${ROLLBAR_SOURCEMAP_HOSTNAME}${match[1]}`;
                }
            }
        }
    }
}

function injectGlobalContextTransform(message: LogObject, context: LoggerContext): TransformResult {
    let url = '';

    // Not using process.env.IS_CLIENT since i'm checking to prevent a null ref exception
    if (typeof window !== 'undefined') {
        url = window.location.toString();
    }

    return {
        message: {
            source: 'client',
            ...message,
        },
        context: {
            sessionKey,
            url,
            ...context,
        },
    };
}
// Maintain getter to be consisent with server logger access pattern
export function buildLogger(appContext: string): Logger {
    const rollbarConfig: Configuration = {
        ...config.client.rollbar.notifier,
        transform: rollbarTransform,
        payload: {
            ...config.client.rollbar.notifier.payload,
            sessionKey,
            context: appContext,
            // TODO get public-safe user identifier and use here
            person: {
                // email: '',
                id: '',
                // username: '',
            },
        },
    };

    const rollbarTransport = rollbarTransporter(environment, rollbarConfig, config.client.rollbar.global);

    const transports: Transport[] = [rollbarTransport];

    if (environment === 'development') {
        transports.unshift(consoleTransporter);
    }

    const logger = new Logger({
        ...config.client.logger,
        transports,
        transforms: [injectGlobalContextTransform],
    });

    return logger;
}
