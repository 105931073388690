import { QueryFunctionContext } from '@tanstack/react-query';
import { WesService } from 'services/WesService';

export const QUERY_KEY = 'favoritesByProductKey';

export const queryFavorites = (
    context: QueryFunctionContext<VP.DTT.Queries.WorkEntityService.ByProductKeyQueryKey>,
): Promise<VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo>[]> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        locale,
        wesTenant,
        ownerId,
        accessToken,
        productKey,
    }] = queryKey;

    return WesService.getAllFavorites(locale, wesTenant, ownerId, accessToken, productKey);
};
