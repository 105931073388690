import { queryBatchDifferentialPricing, QUERY_KEY as DIFF_QUERY_KEY } from 'client/queries/queryBatchDifferentialPricing';
import { UseQueryResult, useQuery, UseQueryOptions } from '@tanstack/react-query';

type DifferentialKey = VP.PCT.Queries.ProductCatalogPricingService.DifferentialPriceQueryKey;

type DifferentialResult = VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null;

export const useBatchFullProductOptionPricing = (
    params: VP.PCT.Queries.ProductCatalogPricingService.DifferentialPriceQueryKeyParameters,
    fullProductOptions?: Gallery.ContentQuery.ProductOptions,
    options?: UseQueryOptions<DifferentialResult, Error, DifferentialResult, DifferentialKey>,
): UseQueryResult<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null | undefined> => {
    const fullChoiceGroups = params.choiceGroups
        && Object.entries(params.choiceGroups).reduce((newObj, [key, value]) => {
            // eslint-disable-next-line no-param-reassign
            newObj[key] = { ...fullProductOptions, ...value };

            return newObj;
        }, {} as VP.PCT.Models.ProductCatalogPricingService.ChoiceGroups);

    return useQuery(
        [DIFF_QUERY_KEY, {
            ...params,
            choiceGroups: fullChoiceGroups,
        }],
        queryBatchDifferentialPricing,
        options,
    );
};
