import {
    ComponentProps,
    ComponentType,
    forwardRef,
} from 'react';
import {
    Tile,
    TileActionArea,
    TileContent,
    TileHeader,
} from 'client/components/common/Tile';
import { PreviewImage } from 'client/components/common/PreviewImage';
import { MAXIMUM_PREVIEW_HEIGHT } from 'src/client/constants';
import { LinkWithQuery } from 'client/components/common/LinkWithQuery';

interface LinkPropsWithFlag extends ComponentProps<typeof LinkWithQuery> {
    shouldShowRevisedPdp?: boolean;
}

export type ImageProps = Partial<ComponentProps<typeof PreviewImage>> & Pick<ComponentProps<typeof PreviewImage>, 'maxPreviewHeight'>;
interface PropTypes extends React.HTMLProps<HTMLElement> {
    Image?: ComponentType<ComponentProps<typeof PreviewImage>>;
    imageProps: ImageProps;
    linkProps?: LinkPropsWithFlag;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseEnter?: () => void;
    imageStyleProps?: React.CSSProperties;
    maxHeight?: number;
    className?: string;
}
const PADDING_VERTICAL = 16;
const PADDING_HORIZONTAL = 12;

const style = {
    padding: `${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px`,
};

export const DesignTile = forwardRef<HTMLLIElement, PropTypes>((props: PropTypes, ref): JSX.Element => {
    const {
        children,
        Image = PreviewImage,
        imageProps,
        linkProps,
        onClick,
        onMouseEnter,
        imageStyleProps = style,
        maxHeight = MAXIMUM_PREVIEW_HEIGHT,
        className,
    } = props;

    const handleKeyPress = (event: React.KeyboardEvent): void => {
        if (onClick) {
            onClick(event as unknown as React.MouseEvent<HTMLButtonElement>);
        }
    };

    const tileActionAreaStyle = {
        maxHeight: `${maxHeight}px`,
    };

    return (
        <Tile
            className={className}
            ref={ref}
            type="design-tile"
        >
            {linkProps ? (
                <LinkWithQuery {...linkProps}>
                    <TileActionArea
                        style={tileActionAreaStyle}
                        onClick={onClick}
                        onKeyPress={handleKeyPress}
                        onMouseEnter={onMouseEnter}
                    >
                        <TileHeader>
                            <Image {...imageProps} style={imageStyleProps} />
                        </TileHeader>
                    </TileActionArea>
                </LinkWithQuery>
            ) : (
                <TileActionArea
                    style={tileActionAreaStyle}
                    onClick={onClick}
                    onKeyPress={handleKeyPress}
                    onMouseEnter={onMouseEnter}
                >
                    <TileHeader>
                        <Image {...imageProps} style={imageStyleProps} />
                    </TileHeader>
                </TileActionArea>
            )}
            <TileContent>
                {children}
            </TileContent>
        </Tile>
    );
});

DesignTile.displayName = 'DesignTile';
