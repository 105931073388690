// Copied from https://github.com/theKashey/use-callback-ref
// License MIT
import { MutableRefObject, useState } from 'react';

/**
 * creates a MutableRef with ref change callback
 * @param initialValue - initial ref value
 * @param {Function} callback - a callback to run when value changes
 *
 * @example
 * const ref = useCallbackRef(0, (newValue, oldValue) => console.log(oldValue, '->', newValue);
 * ref.current = 1;
 * // prints 0 -> 1
 *
 * @see https://reactjs.org/docs/hooks-reference.html#useref
 * @see https://github.com/theKashey/use-callback-ref#usecallbackref---to-replace-reactuseref
 * @returns {MutableRefObject}
 */
export function useCallbackRef<T>(
    initialValue: T | null,
    callback: (newValue: T | null, lastValue: T | null) => void,
): MutableRefObject<T | null> {
    const [ref] = useState(() => ({
        // value
        value: initialValue,
        // last callback
        callback,
        // "memoized" public interface
        facade: {
            get current(): T | null {
                return ref.value;
            },
            set current(value) {
                const last = ref.value;

                if (last !== value) {
                    ref.value = value;
                    ref.callback(value, last);
                }
            },
        },
    }));

    // update callback
    ref.callback = callback;

    return ref.facade;
}
