import classnames from 'classnames';
import { ButtonHTMLAttributes, KeyboardEvent, MouseEvent } from 'react';

export interface PropTypes extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    onKeyPress?: (event: KeyboardEvent<HTMLButtonElement>) => void;
}

export const TileActionArea = (props: PropTypes): JSX.Element => {
    const { children, className, ...rest } = props;

    return (
        <button
            className={classnames('clickable tile-action-area', className)}
            {...rest}
            tabIndex={0}
            type="button"
        >
            {children}
        </button>
    );
};

TileActionArea.displayName = 'TileActionArea';
