/**
 * Parses a srcSet and remove the customImages query param from the DTeR string if there is one. Otherwise
 * returns undefined.
 *
 * @param srcSet the original srcset
 * @returns a new srcSet string with custom images removed or undefined
 */
export const generateSrcSetWithoutCustomImages = (srcSet: string | undefined): string | undefined => {
    try {
        // the substring call is to remove the " 1x" and " 2x" suffixes
        const srcSetUrls = srcSet?.split(', ').map((srcSetUrl) => srcSetUrl.substring(0, srcSetUrl.length - 3));

        if (!srcSetUrls) {
            throw new Error('No srcSet URLs');
        }

        const newSrcSet = srcSetUrls.map((srcSetUrl, index) => {
            const renderingUrl = new URL(srcSetUrl);
            const instructionsUrlString = renderingUrl.searchParams.get('instructions_uri');

            if (!instructionsUrlString) {
                throw new Error('No instructions URI');
            }

            const instructionsUrl = new URL(instructionsUrlString);
            const dterUrlString = instructionsUrl.searchParams.get('documentUri');

            if (!dterUrlString) {
                throw new Error('No DTeR document URI');
            }

            const dterUrl = new URL(dterUrlString);
            const customImages = dterUrl.searchParams.get('customImages');

            if (!customImages) {
                throw new Error('No custom images');
            }

            dterUrl.searchParams.delete('customImages');
            instructionsUrl.searchParams.set('documentUri', dterUrl.toString());
            renderingUrl.searchParams.set('instructions_uri', instructionsUrl.toString());

            return `${renderingUrl.toString()} ${index + 1}x`;
        });

        return newSrcSet.join(',');
    } catch (_e) {
        return undefined;
    }
};
