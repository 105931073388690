import { useQuery } from '@tanstack/react-query';
import config from 'config';
import { PRICING_STALE_TIME, PRICING_CACHE_TIME } from 'client/constants';
import { queryDefaultQuantities, QUERY_KEY } from 'client/queries/queryDefaultQuantities';
import { getDefaultQuantity } from 'client/utils/getDefaultQuantity';

export interface UseQuantitiesInput {
    productKey?: string;
    productVersion?: number;
    locale: i18n.Locale;
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>;
}

export const useQuantities = (params: UseQuantitiesInput): VP.MTT.Models.MsxService.QuantitiesDataResult => {
    const {
        productKey,
        productVersion,
        locale,
        quantity,
    } = params;

    // Fetch the Default Quantities (already prefetched server-side)
    const { data: quantitiesData } = useQuery(
        [QUERY_KEY, {
            productKey,
            version: productVersion,
            tenant: config.tenant,
            locales: [locale],
        }],
        queryDefaultQuantities,
        {
            enabled: !!productKey && !!productVersion,
            staleTime: PRICING_STALE_TIME,
            cacheTime: PRICING_CACHE_TIME,
        },
    );

    return {
        quantitiesData,
        productQuantity: getDefaultQuantity({ locale, quantity, quantitiesData }),
    };
};
