import vpTracking from '@vp/tracking';
import { instrumenter } from 'client/utils/instrumentation';
import { PAGE_ACTION_TYPES } from 'client/constants';
import { getLogger } from 'client/utils/gallery/logger';

export function analyticsOnError(error: vpTracking.TrackingError): void {
    const errorMetadata = {
        message: error.message,
        event: error.eventName,
        url: window?.location?.href,
    };

    instrumenter.recordPageAction(PAGE_ACTION_TYPES.SEGMENT_EVENT_FAILURE, errorMetadata);
    getLogger().error('Segment event failed to send', null, errorMetadata);
}
