import config from 'config';

import { AbstractService, AbstractServiceConfig, LoggerParameter } from 'services/AbstractService';
import { ServiceError } from 'services/errors';
import { getLogger } from 'client/utils/favorites/logger';
import { parseWorkEntity } from 'client/utils/parseWorkEntity';

class WesServiceApi extends AbstractService implements VP.DTT.Services.WorkEntityService.IWesService {
    apiVersion: number;

    constructor({ version, ...rest }: AbstractServiceConfig, logger: LoggerParameter) {
        super(rest, logger);
        this.apiVersion = version;
    }

    /**
     * Add favorite to WES
     * @param wesTenant
     * @param favorite
     * @param ownerId
     * @param accessToken
     */
    public async addFavorite(
        wesTenant: string,
        favorite: VP.DTT.Models.WorkEntityService.WorkEntity<string>,
        ownerId?: string,
        accessToken?: string,
    ): Promise<string> {
        const url = `/api/${this.apiVersion}/works`;

        if (ownerId === undefined || accessToken === undefined) {
            throw new ServiceError({
                favorite,
                url,
                message: `Unable to call addFavorite, no ownerId and/or accessToken provided`,
            });
        }

        try {
            const response = await this.api.post(
                url,
                favorite,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        ownerId,
                        tenant: wesTenant,
                        from: config.appName,
                    },
                },
            );

            const respFave = response.data as VP.DTT.Models.WorkEntityService.WorkEntity<string>;

            // TODO throw error here if we get a bad response from WES
            return respFave.workId || '0';
        } catch (e) {
            throw new ServiceError({
                favorite,
                url,
                message: `Bad response from WES: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    /**
     * Remove favorite from WES
     * @param favoriteId
     * @param accessToken
     */
    public async removeFavorite(favoriteId: string, accessToken?: string): Promise<number> {
        const url = `/api/${this.apiVersion}/works/${favoriteId}/delete`;

        if (accessToken === undefined) {
            throw new ServiceError({
                favoriteId,
                url,
                message: `Unable to call removeFavorite, no accessToken provided`,
            });
        }

        try {
            const response = await this.api.delete(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        from: config.appName,
                    },
                },
            );

            return response.status;
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from WES: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    /**
     * Set favorite visibility in WES
     * @param favoriteId
     * @param isVisible
     * @param accessToken
     */
    public async setFavoriteVisibility(favoriteId: string, isVisible: boolean, accessToken?: string): Promise<number> {
        const url = `/api/${this.apiVersion}/works/${favoriteId}/setVisible`;

        if (accessToken === undefined) {
            throw new ServiceError({
                favoriteId,
                url,
                message: `Unable to call setVisible, no accessToken provided`,
            });
        }

        try {
            const data = { hidden: isVisible };
            const response = await this.api.patch(
                url,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        from: config.appName,
                    },
                },
            );

            return response.status;
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from WES: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    /**
     * Get favorite from WES
     * @param favoriteId
     * @param accessToken
     */
    public async getFavorite(
        favoriteId: string,
        accessToken?: string | null,
    ): Promise<VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo | null>> {
        const url = `/api/${this.apiVersion}/works/${favoriteId}`;

        if (!accessToken) {
            throw new ServiceError({
                favoriteId,
                url,
                message: `Unable to call getFavorite, no accessToken provided`,
            });
        }

        try {
            const response = await this.api.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        from: config.appName,
                    },
                },
            );

            return parseWorkEntity(response.data);
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from WES: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    /**
     * Gets a page of favorites from WES, optionally filtered by gallery/productKey
     * @param locale
     * @param pageSize
     * @param pageNumber
     * @param wesTenant
     * @param ownerId
     * @param accessToken
     * @param productKey
     * @param includeHidden
     */
    public async getFavoritesPage(
        locale: string,
        pageSize: number,
        pageNumber: number,
        wesTenant: string,
        ownerId?: string,
        accessToken?: string | null,
        productKey?: string,
    ): Promise<VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo>[]> {
        const url = `/api/v2/works:search`;

        if (!ownerId || !accessToken) {
            throw new ServiceError({
                url,
                message: `Unable to call getFavoritesPage, no ownerId and/or accessToken provided`,
            });
        }

        try {
            const response = await this.api.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Cache-Control': 'max-age=0, no-cache, no-store',
                    },
                    params: {
                        tenants: wesTenant,
                        pageSize,
                        pageNumber,
                        ownerId,
                        workName: locale,
                        sortBy: `LastCreated`,
                        from: config.appName,
                        productKey: productKey || undefined,
                    },
                },
            );

            return response.data.map(
                (favorite: VP.DTT.Models.WorkEntityService.WorkEntity<string>) => parseWorkEntity(favorite),
            );
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from WES: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    /**
     * Get total favorites count from WES
     * @param tenant
     * @param ownerId
     * @param accessToken
     */
    public async getFavoritesCount(
        tenant: string,
        ownerId?: string,
        accessToken?: string | null,
        productKey?: string,
    ): Promise<VP.DTT.Models.WorkEntityService.CountResponse> {
        const url = `/api/v2/works/filteredCount`;

        if (!ownerId || !accessToken) {
            throw new ServiceError({
                url,
                message: `Unable to call getFavoritesCount, no ownerId and/or accessToken provided`,
            });
        }

        try {
            const response = await this.api.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Cache-Control': 'max-age=0, no-cache, no-store',
                    },
                    params: {
                        tenants: tenant,
                        ownerId,
                        from: config.appName,
                        productKey,
                    },
                },
            );

            return response.data as VP.DTT.Models.WorkEntityService.CountResponse;
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from WES: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    public async getAllFavorites(
        locale: string,
        wesTenant: string,
        ownerId?: string,
        accessToken?: string | null,
        productKey?: string,
    ): Promise<VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo>[]> {
        const pageSize = 50;
        const numFavorites = (await this.getFavoritesCount(wesTenant, ownerId, accessToken, productKey)).ownedWorks;

        let allFavorites = <VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo>[]>[];

        const pages = numFavorites <= 100
            ? Array.from(Array(Math.ceil(numFavorites / pageSize)).keys())
            : [0, 1];

        const promises = pages.map((page) => (
            this.getFavoritesPage(locale, pageSize, page, wesTenant, ownerId, accessToken, productKey)
        ));

        const pagedFavorites = await Promise.all(promises);

        allFavorites = pagedFavorites.flat();

        const favorites = allFavorites?.map((obj) => ({ ...obj, isFavorite: true }));

        return favorites;
    }
}

export const WesService = new WesServiceApi(config.services.wesService, getLogger);
