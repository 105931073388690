import { ServiceError } from 'services/errors/ServiceError';

export type InvalidResponseErrorPayload = Error | {
    [key: string]: any;
    message: string;
    url: string;
};

export class InvalidResponseError extends ServiceError {
    constructor(payload: InvalidResponseErrorPayload, info?: Error) {
        super({ status: 500, ...payload }, info);
        this.name = 'InvalidResponseError';
    }
}
