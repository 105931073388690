import classnames from 'classnames';
import { HTMLProps } from 'react';

export const TileHeader = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { children, className, ...rest } = props;

    return (
        <div className={classnames('tile-header', className)} {...rest}>
            {children}
        </div>
    );
};

TileHeader.displayName = 'TileHeader';
