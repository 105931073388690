import { ColorSwatch, SelectionSetInput, SelectionSetLabel } from '@vp/swan';
import { ColorSwatchWrapperProps } from '~/client/components/common/ColorSwatchPicker/components/ColorSwatchWrapper/interface';
import { getColorSwatchColors } from '~/client/utils/getColorSwatchColors';
import { useMemo } from 'react';

export const ColorSwatchWrapper = ({
    colorSwatch, locale, isColorSwatchColorsSimilar,
}: ColorSwatchWrapperProps): JSX.Element => {
    const { designId, colorComposition = [] } = colorSwatch;

    const {
        primaryColor,
        secondaryColor,
        title,
    } = useMemo(
        () => getColorSwatchColors(locale, colorComposition, isColorSwatchColorsSimilar),
        [locale, colorComposition, isColorSwatchColorsSimilar],
    );

    return (
        <SelectionSetInput
            aria-label={title}
            key={designId}
            value={designId}
        >
            <SelectionSetLabel>
                <ColorSwatch
                    as="span"
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    title={title}
                >
                    <span className="screen-reader-text">{title}</span>
                </ColorSwatch>
            </SelectionSetLabel>
        </SelectionSetInput>
    );
};
