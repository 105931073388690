import {
    buildIdentityState, IdentityState, init, webAuth,
} from 'client/utils/auth';
import {
    ComponentProps, createContext, useEffect, useState,
} from 'react';

export const AuthContext = createContext<IdentityState | null>(null);

export const AuthProvider = (
    props: Omit<ComponentProps<typeof AuthContext.Provider>, 'value'> & { locale: i18n.Locale, signoutRedirectUrl: string },
): JSX.Element => {
    const {
        locale,
        signoutRedirectUrl,
        ...rest
    } = props;
    const [identity, setIdentity] = useState<IdentityState | null>(null);

    useEffect(() => {
        const task = async (): Promise<void> => {
            await init(locale, signoutRedirectUrl);

            webAuth.onUserIdentityUpdate(() => setIdentity(buildIdentityState(webAuth)));
            setIdentity(buildIdentityState(webAuth));
        };

        task();
    }, [locale, signoutRedirectUrl]);

    return (
        <AuthContext.Provider {...rest} value={identity} />
    );
};
