import newrelic from 'newrelic';

export type PageMetadata = {
    [key: string]: string | number | undefined;
    locale: string;
    entityCount?: number;
    galleryName?: string;
    galleryId?: number;
    productKey?: string;
    quickViewId?: string;
};

class BrowserInstrumentation {
    newrelic: Newrelic.NewrelicBrowser;

    constructor() {
        this.newrelic = <unknown>newrelic as Newrelic.NewrelicBrowser;

        // There is a mismatch between Newrelic APM and Newrelic
        // Browser around setting custom attributes. APM has
        // `addCustomAttribute` while Browser has
        // `setCustomAttribute`. Proxy the later for the former for
        // simplicity sake
        if (this.newrelic && !this.newrelic.addCustomAttribute) {
            this.newrelic.addCustomAttribute = this.newrelic.setCustomAttribute;
        }
    }

    recordPageAction(name: string, payload: Record<string, any>): void {
        try {
            if (this.newrelic && this.newrelic.addPageAction) {
                this.newrelic.addPageAction(name, {
                    ...payload,
                });
            }
        } catch (e) {
            // best effort - don't break the page
        }
    }

    /**
     * Creates a `PageAction` event in Newrelic for the `designSelection`
     * action the user performed
     *
     * @param payload
     * @param selectionType
     */
    recordDesignSelection(payload: Record<string, any>, selectionType: string): void {
        this.recordPageAction('design-selection', {
            selectionType,
            ...payload,
        });
    }

    /**
     * Creates a `PageAction` event in Newrelic for the `pageReady` action
     * with the time it took until the user is first able to interact with the
     * app
     */
    recordAppHydratedEvent(): void {
        if (!this.newrelic) {
            return;
        }

        if (process.env.IS_CLIENT) {
            const timing = (Date.now() - window.performance.timing.navigationStart) / 1000.0;

            this.recordPageAction('application-hydrated', {
                timing,
            });
        }
    }

    /**
     * Sets the provided values as default attributes/metadata to the
     * instrumentation to be recorded on all actions/events
     *
     * @param params
     */
    setPageView(page: string, params: PageMetadata): void {
        if (!this.newrelic) {
            return;
        }

        for (const [key, value] of Object.entries(params)) {
            if (value) {
                this.newrelic.addCustomAttribute(key, value);
            }
        }

        // Keeping culture for backwards compatibility
        this.newrelic.addCustomAttribute('culture', params.locale);

        this.newrelic.setPageViewName(page);
    }

    recordImageFailure(payload: Record<string, any>): void {
        this.recordPageAction('image-failure', {
            ...payload,
        });
    }

    recordImagesLoaded(textPersonalized: boolean, imagePersonalized: boolean): void {
        const imageLoadedTimes = window.imageResources.map((entry) => entry.responseEnd - entry.startTime);

        if (imageLoadedTimes.length > 3) {
            window.imageResources = [];
            const minImageLoadedTime = Math.min(...imageLoadedTimes);
            const maxImageLoadedTime = Math.max(...imageLoadedTimes);
            const meanImageLoadedTime = imageLoadedTimes.reduce((sum, time) => sum + time, 0) / imageLoadedTimes.length;

            this.recordPageAction('images-loaded', {
                imageLoadedCount: imageLoadedTimes.length,
                minImageLoadedTime,
                maxImageLoadedTime,
                meanImageLoadedTime,
                textPersonalized,
                imagePersonalized,
            });
        }
    }
}

export const instrumenter = new BrowserInstrumentation();
