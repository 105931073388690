import config from 'config';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ProductCatalogPricingService } from 'services/ProductCatalogPricingService';
import { CHOICE_GROUP_BATCH_SIZE } from 'client/constants';

import { getLogger } from 'client/utils/gallery/logger';

export const QUERY_KEY = 'differential_pricing';

const productCatalogPricingService = new ProductCatalogPricingService(
    config.services.productCatalogPricingService,
    getLogger,
);

export const queryBatchDifferentialPricing = async (
    context: QueryFunctionContext<VP.PCT.Queries.ProductCatalogPricingService.DifferentialPriceQueryKey>,
): Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        market,
        productKey,
        quantity,
        productVersion,
        choiceGroups,
        pricingContext,
        couponCode,
        effectiveDateTime,
        customerGroups,
        selections,
    }] = queryKey;

    const requests: Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null>[] = [];

    const batchableChoiceGroups = Object.entries(choiceGroups || {});
    const optionCount = batchableChoiceGroups.length ? Object.keys(batchableChoiceGroups[0][1]).length : 1;

    // Make sure our request isn't too big, split choiceGroups into batches of roughly 6000 characters
    // Total character length roughly estimated by character length of choiceGroups object
    // plus 25 extra characters for every product option in every choice group because of repeated characters
    const batchSize = choiceGroups
        ? Math.min(
            Math.floor(
                batchableChoiceGroups.length / (
                    (JSON.stringify(choiceGroups).length + batchableChoiceGroups.length * optionCount * 25) / 6000),
            ),
            CHOICE_GROUP_BATCH_SIZE,
        )
        : CHOICE_GROUP_BATCH_SIZE;

    for (let i = 0; i < batchableChoiceGroups.length; i += batchSize) {
        const batchedChoiceGroups = batchableChoiceGroups.slice(i, i + batchSize)
            .reduce((result, [key, value]) => {
                // eslint-disable-next-line no-param-reassign
                result[key] = value;

                return result;
            }, {} as VP.PCT.Models.ProductCatalogPricingService.ChoiceGroups);

        const batchedRequest = productCatalogPricingService.getDifferentialPrice(
            market,
            productKey,
            quantity,
            productVersion,
            batchedChoiceGroups,
            pricingContext,
            couponCode,
            effectiveDateTime,
            customerGroups,
            selections,
        );

        requests.push(batchedRequest);
    }

    const responses = await Promise.all(requests);

    const responsesChoiceGroups = responses.reduce(
        (accum, response) => ({
            ...accum,
            ...response?.choiceGroups,
        }),
        {} as Promise<VP.PCT.Models.ProductCatalogPricingService.ChoiceGroupsPricing | null>,
    );

    return {
        ...responses.reduce(
            (accum, response) => ({
                ...accum,
                ...response,
            }),
            {} as Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null>,
        ),
        choiceGroups: responsesChoiceGroups,
    };
};
