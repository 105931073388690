import classnames from 'classnames';
import { memo } from 'react';
import { Button, Icon, Typography } from '@vp/swan';

interface PropTypes extends React.ComponentProps<typeof Button> {
    active: boolean;
    shoulHideMoreArrowIcon?: boolean;
}

export const ShowMoreButton = memo((props: PropTypes): JSX.Element => {
    const {
        active,
        children,
        className,
        shoulHideMoreArrowIcon,
        ...rest
    } = props;

    return (
        <Button
            className={classnames('show-more-button', 'simple', className, {
                active,
            })}
            iconPosition="right"
            skin="unstyled"
            {...rest}
        >
            {children && <Typography className="show-more-text" fontSize="small" fontWeight="bold">{children}</Typography>}
            {!shoulHideMoreArrowIcon && <Icon iconType={active ? 'caretUp' : 'caretDown'} />}
        </Button>
    );
});

ShowMoreButton.displayName = 'ShowMoreButton';
