import config from 'config';
import { parseUrl, stringifyUrl } from 'query-string';

const PACKAGING_STUDIO_URL_PATH = '/packaging-studio';

export type VistaprintUrlParameters = {
    path: string,
    locale: i18n.Locale,
    quantity?: number | null,
    mpvId?: string | null,
    productKey?: string | null,
    selectedOptions?: Record<string, string> | null,
    fullBleedElected?: boolean,
    impressionId?: string,
    galleryFullbleedEngagementId?: string,
};

export const buildFullyQualifiedVistaprintUrl = (
    params: VistaprintUrlParameters,
): string => {
    const {
        path,
        locale,
        quantity,
        mpvId,
        productKey,
        selectedOptions,
        fullBleedElected,
        impressionId,
        galleryFullbleedEngagementId,
    } = params;

    const culturePrefixSetting = config.client.culturePrefix[locale.toLowerCase()];

    let culturePrefixToAdd = '';

    // Check to make sure we don't accidentily add the culture prefix twice
    if (culturePrefixSetting) {
        const culturePrefix = `/${culturePrefixSetting}`;
        const hasCulturePrefix = path.toLocaleLowerCase().startsWith(`${culturePrefix}/`);
        const hasPackagingStudioPath = path.startsWith(PACKAGING_STUDIO_URL_PATH);
        const shouldAddCulturePrefix = !hasCulturePrefix && !hasPackagingStudioPath;

        if (shouldAddCulturePrefix) {
            culturePrefixToAdd = culturePrefix;
        }
    }

    const studioUrl = `${config.client.vistaprintRoot[locale.toLowerCase()]}${culturePrefixToAdd}${path}`;
    const { url, query } = parseUrl(studioUrl);

    if (quantity) {
        query.qty = quantity.toString();
    }

    if (mpvId) {
        query.mpvId = mpvId;
    }

    if (selectedOptions) {
        query.selectedOptions = JSON.stringify(selectedOptions);
    }

    if (productKey) {
        query.key = productKey;
    }

    if (!('locale' in query)) {
        query.locale = locale;
    }

    if (fullBleedElected) {
        query.fullBleedElected = 'true';
    }

    if (impressionId) {
        query.metadata = JSON.stringify(
            { galleryImpressionId: impressionId },
        );
    }

    if (galleryFullbleedEngagementId) {
        query.metadata = JSON.stringify(
            { galleryFullbleedEngagementId },
        );
    }

    return stringifyUrl({ url, query });
};
