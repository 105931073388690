import queryString from 'query-string';
import qs from 'qs';

import { REFINEMENT_DIMENSION } from 'shared/constants';
import { DEFAULT_PAGING_SIZES } from 'client/store/paging/constants';
import { matchGalleryParams } from 'client/utils/matchGalleryParams';

/**
 * Converts a value in multiple formats to an array.
 *   if the value is a string, returns an array with the strong as the only item
 *   if the value is null or undefined, returns an empty array
 * @param value
 */
function getArrayFromValue(value?: Gallery.Models.Url.ValidQsValue): string[] {
    if (!value) {
        return [];
    }

    return (Array.isArray(value) ? value : [value]).map((item) => {
        if (item === null) {
            return null;
        }
        return typeof item === 'string' ? item : item.toString();
    }).filter((item): item is string => (item !== null));
}

/**
 * Converts a value in multiple formats to a single string
 *   if the value is an array, only the first item is returned
 * @param value
 */
function getStringFromValue(value: Gallery.Models.Url.ValidQsValue): Gallery.Models.Url.ValidParsedQsValue<string> {
    if (value === null || value === undefined) {
        return value;
    }

    const ret = Array.isArray(value) ? value[0] : value;

    if (ret === null) {
        return null;
    }

    return typeof ret === 'string' ? ret : ret.toString();
}

/**
 *
 * @param value
 */
function getNumberFromQuery(value?: Gallery.Models.Url.ValidQsValue): Gallery.Models.Url.ValidParsedQsValue<number> {
    if (value === null || value === undefined) {
        return value;
    }

    return value ? parseInt(getStringFromValue(value) as string, 10) : null;
}

/**
 *
 * @param value
 */
function getDecimalFromQuery(value?: Gallery.Models.Url.ValidQsValue): Gallery.Models.Url.ValidParsedQsValue<number> {
    if (value === null || value === undefined) {
        return value;
    }

    return value ? parseFloat(getStringFromValue(value) as string) : null;
}

/**
 * Resolve MPV ID from two possible variations on the variable name in the qs
 * @param query
 */
function getMpvId(query: queryString.ParsedQuery<string>): Gallery.Models.Url.ValidParsedQsValue<string> {
    if (!query.mpvId || !query.mpvId || query.mpv) {
        return null;
    }

    if (query.mpvId) {
        return getStringFromValue(query.mpvId);
    }

    if (query.mpvId) {
        return getStringFromValue(query.mpvId);
    }

    if (query.mpv) {
        return getStringFromValue(query.mpv);
    }

    return null;
}

/**
 * Finds all the parameters in the parsed query map that start with the
 * provided `root` string and return them in a containing object; otherwise
 * return `undefined`
 *
 * `undefined` is returned in place of an empty object to be consisent with
 * `query-string`'s behavior since it treats `undefined` as "not present in
 * query string"
 *
 * @param query
 * @param root
 */
function getObjectFromValue<T = Util.StringDictionary<string | number>>(query: qs.ParsedQs, root: string): T {
    return (query[root] || {}) as unknown as T;
}

/**
 * Converts a parsed QS (qs library) value into an array of string
 *   if the value is a string or an array of string, returns an array
 *   if the value is undefined or any type of ParsedQs (unhandled case at the moment), returns an empty array
 * @param value
 * @param root
 */
function getArrayFromQSValue(value: qs.ParsedQs, root: string): string[] {
    if (!value) {
        return [];
    }

    const rootValue = value[root];

    if (typeof rootValue === 'string') {
        return rootValue.split(',');
    }

    return Array.isArray(rootValue) && rootValue.length
        ? rootValue.map((item: any) => (typeof item === 'string' ? item : null)).filter((item): item is string => (item !== null))
        : [];
}

/**
 * Apply defaults to the query string whenever the specified elements are undefined
 *
 * @param rawQuery
 */
function applyDefaultsForQuery(rawQuery: queryString.ParsedQuery<string>): queryString.ParsedQuery<string> {
    return {
        useConstraints: rawQuery.useConstraints === undefined ? 'true' : rawQuery.useConstraints,
        ...rawQuery,
    };
}

export function getBooleanFromValue(value: Gallery.Models.Url.ValidQsValue): Gallery.Models.Url.ValidParsedQsValue<boolean> {
    return getStringFromValue(value)?.toLowerCase() === 'true';
}

/**
 * Parses a gallery url to get the gallery context
 * @param inputUrl
 */

export function getGalleryContextFromQueryString(inputUrl: string): Gallery.Models.Url.QueryStringState {
    const { query: rawQuery } = queryString.parseUrl(inputUrl, { arrayFormat: 'comma' });
    const parsedQS = qs.parse(inputUrl.split('?')[1], { ignoreQueryPrefix: true });

    const query = applyDefaultsForQuery(rawQuery);
    let pageSize = getNumberFromQuery(query.pageSize);

    if (pageSize) {
        pageSize = Math.min(pageSize, Math.max(...DEFAULT_PAGING_SIZES));
    }

    const categoriesRefinement = getArrayFromValue(query.categories)
        .filter((cat) => Number(cat) > 0);

    return {
        query,
        bypassApproval: getBooleanFromValue(query.bypassApproval),
        debug: getBooleanFromValue(query.debug),
        mpvId: getMpvId(query),
        noCache: getBooleanFromValue(query.noCache),
        useConstraints: getBooleanFromValue(query.useConstraints),
        paging: {
            page: getNumberFromQuery(query.page),
            pageSize,
        },
        quantity: getNumberFromQuery(query.qty),
        refinements: {
            [REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL]: getArrayFromQSValue(parsedQS, 'attributes'),
            [REFINEMENT_DIMENSION.CATEGORY_PLURAL]: categoriesRefinement,
            [REFINEMENT_DIMENSION.TEMPLATE_USE_CASES]: getArrayFromValue(query.templateUseCases),
            [REFINEMENT_DIMENSION.KEYWORD]: parsedQS.keyword as string,
            [REFINEMENT_DIMENSION.COLLECTION]: getStringFromValue(query.collection),
        },
        renderPropsOverride: getObjectFromValue<State.RenderPropsState>(parsedQS, 'renderPropsOverride'),
        rankingStrategyOverride: getStringFromValue(query.rankingStrategyOverride),
        forcedRankingStrategyOverride: getStringFromValue(query.forcedRankingStrategyOverride),
        selectedOptions: getObjectFromValue(parsedQS, 'selectedOptions'),
        selectedProduct: getStringFromValue(query.selectedProduct),
        forceVariation: getArrayFromValue(query.forceVariation),
        quickViewId: getStringFromValue(query.quickView) || matchGalleryParams(inputUrl).designId,
        useRealisationEngineService: getBooleanFromValue(query.useRealisationEngineService),
        enrich: getBooleanFromValue(query.enrich),
        isProduct: getBooleanFromValue(query.isProduct),
        pageId: getStringFromValue(query.pageId),
        experienceType: getStringFromValue(query.experienceType),
        templatePurposes: getArrayFromQSValue(parsedQS, 'templatePurposes') as State.TEMPLATE_PURPOSES[],
        tlpLevel: getStringFromValue(query.tlpLevel),
        sortingStrategy: getStringFromValue(query.sortingStrategy),
        imagePlaceholderAspectRatio: getDecimalFromQuery(query.imagePlaceholderAspectRatio),
        imagePlaceholderAspectRatioTolerance: getDecimalFromQuery(query.imagePlaceholderAspectRatioTolerance),
        placeholderPurposes: getArrayFromValue(query.placeholderPurposes),
        useAlternateSearchProvider: getBooleanFromValue(query.useAlternateSearchProvider),
        searchBackend: getStringFromValue(query.searchBackend),
        source: getStringFromValue(query.source),
        designCreationTypes: getArrayFromValue(query.designCreationTypes),
        aspExperimentFlags: getObjectFromValue(parsedQS, 'aspExperimentFlags'),
        enableTemplateUseCases: getBooleanFromValue(query.enableTemplateUseCases),
        highlightKeywords: getArrayFromValue(query.highlightKeywords),
        highlightCategories: getArrayFromValue(query.highlightCategories),
    };
}

export function getFavoriteContextFromQueryString(inputUrl: string): Gallery.Favorites.Models.Url.QueryStringState {
    const { query: rawQuery, url } = queryString.parseUrl(inputUrl, { arrayFormat: 'comma' });
    const query = applyDefaultsForQuery(rawQuery);

    return {
        url,
        query,
        bypassApproval: getBooleanFromValue(query.bypassApproval),
        noCache: getBooleanFromValue(query.noCache),
    };
}
/**
 * Add a parameter key and value to an existing url
 * @param fullURL
 * @param paramKey
 * @param paramValue
 */
export function addQueryParamToUrl(fullURL: string, paramKey: string, paramValue: string): string {
    const { url, query = {} } = queryString.parseUrl(fullURL);

    query[paramKey] = paramValue;

    return queryString.stringifyUrl({ url, query });
}

/**
 * Add a `page` query param to an existing canonical url
 * @param canonicalUrl
 * @param page
 */
export const adjustCanonicalUrl = (canonicalUrl: string | undefined, page: number | null | undefined): string | undefined => {
    if (!canonicalUrl || !page) {
        return canonicalUrl;
    }

    const url = new URL(canonicalUrl);

    url.searchParams.set('page', page.toString());

    return url.toString();
};
