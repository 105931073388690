export enum RenderProperty {
    ContentBackgroundColor = 'ContentBackgroundColor',
    DisableFullBleed = 'DisableFullBleed',
    ShowComparativeNames = 'ShowComparativeNames',
    PricingDisplay = 'PricingDisplay',
    TilePreviewSize = 'TilePreviewSize',
    PricingPresentationType = 'PricingPresentationType',
    FullBleedUploadPopExperience = 'FullBleedUploadPopExperience',
    DefaultPageSize = 'DefaultPageSize',
    VortexEnabled = 'VortexEnabled',
    ImageCacheVersion = 'ImageCacheVersion',
    Favorites = 'Favorites',
    IconographyEnabled = 'IconographyEnabled',
    ShowBreadcrumbs = 'ShowBreadcrumbs',
    ShowProductHeaderInfo = 'ShowProductHeaderInfo',
    ShowRatings = 'ShowRatings',
    ShowFAQs = 'ShowFAQs',
    ShowProductInfo = 'ShowProductInfo',
    ShowQuicklinks = 'ShowQuicklinks',
    ShowTLPL0Quicklink = 'ShowTLPL0Quicklink',
    ShowProductOptionsDescription = 'ShowProductOptionsDescription',
    ShowPhotoPersonalization = 'ShowPhotoPersonalization',
    ShowPersonalizationUI = 'ShowPersonalizationUI',
    PersonalizationImageCopy = 'PersonalizationImageCopy',
    PersonalizationTextFields = 'PersonalizationTextFields',
    PersonalizationStoreToBrand = 'PersonalizationStoreToBrand',
    PhotoPersonalizationImage = 'PhotoPersonalizationImage',
    SceneSource = 'SceneSource',
    DisableFullBleedUploadForFoilColor = 'DisableFullBleedUploadForFoilColor',
    VortexPreviewAnimation = 'VortexPreviewAnimation',
    ShowNewTemplateTile = 'ShowNewTemplateTile',
    ConsolidateContent = 'ConsolidateContent',
    SocialMediaGallery = 'SocialMediaGallery',
}

export enum PricingDisplay {
    Header = 'Header',
    Tile = 'Tile',
}

export enum FullBleedUploadPopExperienceVersions {
    V2 = 'v2',
}
