import TokenEngine, { ComparativeDisplayType, ZeroDisplayType } from '@vp/vp-js-token-engine';
import QuantityPriceTokenGenerator from '@vp/vp-js-token-engine/build/tokenGenerators/pricing/QuantityPriceTokenGenerator';
import DifferentialTokenGenerator from '@vp/vp-js-token-engine/build/tokenGenerators/pricing/PlusOrMinusTokenGenerator';
import PriceTokenGenerator from '@vp/vp-js-token-engine/build/tokenGenerators/pricing/PriceTokenGenerator';
import TokenGeneratorBase from '@vp/vp-js-token-engine/build/tokenGenerators/TokenGeneratorBase';
import { PRICING_PRESENTATION_TYPES } from 'client/constants';

async function renderToken(key: string, token: string, tokenEngine: TokenEngine): Promise<
VP.MTT.Models.TokenEngine.PricingTextResponse
> {
    const result = await tokenEngine.render(token);

    return { [key]: result };
}

function getTokenGeneratorByType(pricingPresentationType: string): TokenGeneratorBase {
    switch (pricingPresentationType) {
        // Check for default here becuase generators appears to be bundled as a namespace
        // in prod, but not in dev. Not sure why
        case PRICING_PRESENTATION_TYPES.AS_LOW_AS_PRICE_PER_PIECE:
        case PRICING_PRESENTATION_TYPES.RAW_PRICE:
            // @ts-expect-error
            return PriceTokenGenerator.default
                // @ts-expect-error
                // eslint-disable-next-line new-cap
                ? new PriceTokenGenerator.default()
                : new PriceTokenGenerator();
        case PRICING_PRESENTATION_TYPES.DIFFERENTIAL_PRICE_PER_UNIT:
        case PRICING_PRESENTATION_TYPES.QTY_DIFFERENTIAL_PRICE:
            // @ts-expect-error
            return DifferentialTokenGenerator.default
                // @ts-expect-error
                // eslint-disable-next-line new-cap
                ? new DifferentialTokenGenerator.default()
                : new DifferentialTokenGenerator();
        case PRICING_PRESENTATION_TYPES.PRICE_PER_UNIT:
        case PRICING_PRESENTATION_TYPES.QTY_FROM_PRICE:
        default:
            // @ts-expect-error
            return QuantityPriceTokenGenerator.default
                // @ts-expect-error
                // eslint-disable-next-line new-cap
                ? new QuantityPriceTokenGenerator.default()
                : new QuantityPriceTokenGenerator();
    }
}

export async function getChoiceGroupsPricingText(
    pricingPresentationType: string,
    locale: string,
    // eslint-disable-next-line default-param-last
    hideVatMessage = false,
    vatInc?: boolean,
    pricingResponse?: VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult,
    quantity?: number,
    hideShippingMessageInVatMessage?: boolean,
): Promise<VP.MTT.Models.TokenEngine.PricingTextResponse> {
    if (!pricingResponse) {
        throw new Error(
            `Unable to call getChoiceGroupsPricingText. pricingResponse from PCT is required`,
        );
    }

    const tokenEngine = new TokenEngine(locale);
    const tokenGenerator = getTokenGeneratorByType(pricingPresentationType);

    const pricingTextTasks = Object
        .entries(pricingResponse.choiceGroups)
        .reduce((tasks, [key, entry]) => {
            let price;
            let discountPrice;
            let differentialPrice;
            let discountDifferentialPrice;
            const {
                priceCalculationFailed,
                totalListPrice,
                totalDiscountPrice,
                unitListPrice,
                unitDiscountedPrice,
                differentialListPrice,
                differentialDiscountPrice,
                differentialUnitListPrice,
                differentialUnitDiscountPrice,
            } = entry;

            if (priceCalculationFailed) {
                throw new ReferenceError(`Price calculation failed: ${entry.failureReason}`);
            }

            if (!totalListPrice) {
                throw new ReferenceError(`No total list price returned for product ${pricingResponse.productKey}`);
            }

            if (!totalDiscountPrice) {
                throw new ReferenceError(`No total discount price returned for product ${pricingResponse.productKey}`);
            }

            if (!unitListPrice) {
                throw new ReferenceError(`No unit list price returned for product ${pricingResponse.productKey}`);
            }

            if (!unitDiscountedPrice) {
                throw new ReferenceError(`No unit discount price returned for product ${pricingResponse.productKey}`);
            }

            switch (pricingPresentationType) {
                case PRICING_PRESENTATION_TYPES.AS_LOW_AS_PRICE_PER_PIECE:
                case PRICING_PRESENTATION_TYPES.PRICE_PER_UNIT:
                case PRICING_PRESENTATION_TYPES.DIFFERENTIAL_PRICE_PER_UNIT:
                    price = unitListPrice;
                    discountPrice = unitDiscountedPrice;
                    differentialPrice = differentialUnitListPrice;
                    discountDifferentialPrice = differentialUnitDiscountPrice;
                    break;
                case PRICING_PRESENTATION_TYPES.QTY_FROM_PRICE:
                case PRICING_PRESENTATION_TYPES.QTY_DIFFERENTIAL_PRICE:
                case PRICING_PRESENTATION_TYPES.QTY_STARTING_AT_PRICE:
                default:
                    price = totalListPrice;
                    discountPrice = totalDiscountPrice;
                    differentialPrice = differentialListPrice;
                    discountDifferentialPrice = differentialDiscountPrice;
                    break;
            }

            const priceDifference = vatInc ? differentialPrice.taxed : differentialPrice.untaxed;
            const priceDifferenceDiscounted = vatInc
                ? discountDifferentialPrice.taxed
                : discountDifferentialPrice.untaxed;

            const token = tokenGenerator.generateToken({
                culture: locale,
                hideVatMessage,
                quantity,
                priceDifference,
                priceDifferenceDiscounted,
                listPrice: vatInc ? price.taxed : price.untaxed,
                discountPrice: vatInc ? discountPrice.taxed : discountPrice.untaxed,
                currency: pricingResponse.currency,
                minimumFractionDigits: pricingResponse.fractionDigits,
                vatInc: vatInc || false,
                hideShippingMessageInVatMessage: hideShippingMessageInVatMessage || false,
                zeroDisplayType: ZeroDisplayType.Zero,
                comparativeDisplayType: ComparativeDisplayType.CustomerFriendly,
            }, pricingPresentationType);

            tasks.push(renderToken(key, token, tokenEngine));

            return tasks;
        }, [] as Promise<VP.MTT.Models.TokenEngine.PricingTextResponse>[]);

    const pricingTextMappings = await Promise.all(pricingTextTasks);

    return Object.assign({}, ...pricingTextMappings);
}
