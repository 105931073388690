import { AxiosError } from 'axios';

import { AbstractService } from 'services/AbstractService';
import { ServiceError } from 'services/errors';

export class CareCalloutService extends AbstractService implements Services.ICareCalloutService {
    /**
     * Request Care/DIFY Callout availability/translations
     * @param locale
     */
    public async getCareCalloutDetails(locale: i18n.Locale, mpvId?: string | null): Promise<VP.Care.CareData | null> {
        const url = `v1/Callout/${locale}?services=Standard&mpvId=${mpvId ?? ''}`;

        try {
            const response = await this.api.get<VP.Care.CareCalloutResult>(url);

            const {
                channelData: carePhone,
                serviceConfig,
                translations,
                urls,
                temporarilyOutOfStock,
            } = response.data;

            const data: VP.Care.CareData = {
                carePhone,
                translations,
                urls,
                designServicesAvailable: serviceConfig?.Standard?.status === 'Available' && !temporarilyOutOfStock,
                designServicesFree: serviceConfig?.Standard?.price?.discountedPrice === 0,
            };

            return data;
        } catch (e) {
            this.logger.error(new ServiceError({
                url,
                message: `Bad response from care channel provider service: ${(e as Error).message}`,
                response: (e as AxiosError).response,
            }, e as Error));
            return null;
        }
    }
}
