import { memo, FunctionComponent } from 'react';
import { Typography } from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';

interface FallbackPreview {
    size?: 'default' | 'large';
}

const FallbackPreviewComponent: FunctionComponent<FallbackPreview> = ({ size = 'default' }) => {
    const localize = useTranslations();
    const isLargeSize = size === 'large';
    const fontSize = isLargeSize ? 'x2large' : 'small';

    return (
        <span className="fallback-preview">
            {!isLargeSize && (
                <svg className="swan-icon-size-60p" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 3V13H3V3H13ZM12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM9.42667 7.90667L7.42667 10.4867L6 8.76L4 11.3333H12L9.42667 7.90667Z" fill="#9e9e9e" />
                </svg>
            )}

            <Typography
                className="fallback-preview-text"
                component="span"
                fontSize={fontSize}
                fontWeight="bold"
                py="3"
                textAlign="center"
            >
                {localize('NoPreviewAvailable')}
            </Typography>
        </span>
    );
};

export const FallbackPreview = memo(FallbackPreviewComponent);
