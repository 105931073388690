import { parse, stringify } from 'flatted';
import { AxiosError, AxiosResponse } from 'axios';

import { DEFAULT_STATUS } from 'services/constants';

export type ServiceErrorPayload = AxiosError | Error | {
    [key: string]: any;
    message: string;
    status?: number;
    url: string;
};

function resolveMessage(payload: ServiceErrorPayload): string {
    return ('message' in payload) ? payload.message : JSON.stringify(payload);
}

function resolveStatus(payload: ServiceErrorPayload, info?: AxiosError | Error): number {
    if ('status' in payload && payload.status) {
        return payload.status;
    }

    if (!info) {
        return DEFAULT_STATUS;
    }

    // Use the `in` check to cover typechecking errors
    if ('response' in info && info.response && info.response!.status) {
        return info.response!.status;
    }

    // Use the `in` check to cover typechecking errors
    if ('status' in info && (info as any).status) {
        return (info as any).status;
    }

    return DEFAULT_STATUS;
}

function resolveUrlInRequest(request: Record<string, any> = {}): string {
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseURL
    if ('responseURL' in request) {
        // @ts-ignore
        return request.responseURL;
    }

    // NodeJS ClientRequest
    // https://nodejs.org/api/http.html#http_class_http_clientrequest
    if ('path' in request) {
        // @ts-ignore
        return request.path;
    }

    return 'Unknown';
}

function resolveUrl(payload: ServiceErrorPayload): string {
    if ('url' in payload) {
        return payload.url;
    }

    if ('response' in payload) {
        return resolveUrlInRequest(payload.response?.request as Record<string, any>);
    }

    // @ts-ignore
    return resolveUrlInRequest(payload.request);
}

export class ServiceError extends Error {
    info?: Error | AxiosError;

    response?: AxiosResponse;

    requestUrl: string;

    status: number;

    baseUrl?: string;

    constructor(payload: ServiceErrorPayload, info?: AxiosError | Error) {
        const message = resolveMessage(payload);

        super(message);

        if ('stack' in payload && !!payload.stack) {
            this.stack = ` ${payload.stack}`;
        } else if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, ServiceError);
        }

        if (info && (info as AxiosError).response) {
            this.response = (info as AxiosError).response;
        }

        if (info && (info as AxiosError).config) {
            this.baseUrl = (info as AxiosError).config?.baseURL;
        }

        this.status = resolveStatus(payload, info);
        this.requestUrl = resolveUrl(payload);
        this.name = 'ServiceError';

        try {
            // Remove an circular references using flatted
            if (info) {
                const {
                    config,
                    code,
                    message: rawMessage,
                    isAxiosError,
                    stack,
                } = parse(stringify(info));

                const {
                    url,
                    method,
                    baseURL,
                    headers,
                    params,
                    timeout,
                    timeoutErrorMessage,
                    withCredentials,
                    responseType,
                    xsrfCookieName,
                    xsrfHeaderName,
                    maxContentLength,
                    maxBodyLength,
                    maxRedirects,
                    socketPath,
                    decompress,
                } = config || {};

                this.info = {
                    config: {
                        url,
                        method,
                        baseURL,
                        headers,
                        params,
                        timeout,
                        timeoutErrorMessage,
                        withCredentials,
                        responseType,
                        xsrfCookieName,
                        xsrfHeaderName,
                        maxContentLength,
                        maxBodyLength,
                        maxRedirects,
                        socketPath,
                        decompress,
                    },
                    code,
                    message: rawMessage,
                    isAxiosError,
                    stack,
                } as unknown as AxiosError<any>;
            }
        } catch (e) {
            try {
                if (!this.info) {
                    this.info = new Error(`Failed to add info: ${(e as Error).message}`);
                }
            } catch (e2) {
                // best effort
            }
        }
    }
}
