import { getLogger } from 'client/utils/favorites/logger';

function parseFavoriteProductOptions(
    merchandising: VP.DTT.Models.WorkEntityService.Merchandising,
): VP.DTT.Models.WorkEntityService.MerchandisingSelections {
    const options = { fullProductOptions: null, productOptions: null };

    try {
        const { merchandisingSelections } = merchandising;
        const { fullProductOptions, productOptions } = merchandisingSelections;

        options.fullProductOptions = fullProductOptions
            ? JSON.parse(fullProductOptions as string) : { ...merchandisingSelections };

        options.productOptions = productOptions
            ? JSON.parse(productOptions as string) : { ...merchandisingSelections };
    } catch (e) {
        getLogger().error(e as Error);
    }

    return options;
}

function parsePreviewInfo(
    metadata: VP.DTT.Models.WorkEntityService.DesignMetadata<string>,
): Gallery.ContentQuery.PreviewInfo | null {
    if (!metadata.previewInfo || metadata.previewInfo === 'null') {
        return null;
    }

    try {
        return JSON.parse(metadata.previewInfo as string);
    } catch (e) {
        getLogger().error(e as Error);
    }

    return null;
}

export function parseWorkEntity(
    favorite: VP.DTT.Models.WorkEntityService.WorkEntity<string>,
): VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo | null> {
    const { merchandising, design } = favorite;
    const options = parseFavoriteProductOptions(merchandising);
    const parsedPreviewInfo = parsePreviewInfo(design.metadata);

    return {
        ...favorite,
        design: {
            ...design,
            metadata: {
                ...design.metadata,
                previewInfo: parsedPreviewInfo,
            },
        },
        merchandising: {
            ...merchandising,
            merchandisingSelections: options,
        },
    };
}

export function stringifyWorkEntityProperties(
    favorite: VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo>,
): VP.DTT.Models.WorkEntityService.WorkEntity<string> {
    const { merchandising, design } = favorite;

    return {
        ...favorite,
        design: {
            ...design,
            metadata: {
                ...design.metadata,
                previewInfo: JSON.stringify(design.metadata.previewInfo),
            },
        },
        merchandising: {
            ...merchandising,
            merchandisingSelections: {
                ...merchandising.merchandisingSelections,
                fullProductOptions: JSON.stringify(merchandising.merchandisingSelections.fullProductOptions),
                productOptions: JSON.stringify(merchandising.merchandisingSelections.productOptions),
            },
        },
    };
}
