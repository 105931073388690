export function loadImageInBackground(
    handleImageError: (() => void) | null,
    resolvedSrcSet: string | undefined,
    setIsLoading: ((value: React.SetStateAction<boolean>) => void) | null,
    src: string | undefined,
): void {
    if (typeof Image !== 'undefined') {
        const img = new Image();

        img.onerror = handleImageError;
        img.onload = (): void => {
            if (setIsLoading) {
                setIsLoading(false);
            }
        };

        img.crossOrigin = 'anonymous';
        img.srcset = resolvedSrcSet || '';
        img.src = src || '';
    }
}

export const resolveSrc = (src?: string): string | undefined => (src?.startsWith('//') ? `https:${src}` : src);

export function resolveSrcSet(srcInfo?: Gallery.Designs.McpImageSrcInfo, srcSet?: string): string | undefined {
    const size1x = resolveSrc(srcInfo?.size1x);
    const size2x = resolveSrc(srcInfo?.size2x);

    return srcInfo ? `${size1x} 1x, ${size2x} 2x` : srcSet;
}
