import config from 'config';
import { QueryFunctionContext } from '@tanstack/react-query';

import { getLogger } from 'client/utils/gallery/logger';
import { CareCalloutService } from 'services/CareCalloutService';

const servicesConfig: Gallery.Config.ServicesConfig = config.services;

const careCalloutService = new CareCalloutService(servicesConfig.careCalloutService, getLogger);

export const QUERY_KEY = 'careCalloutDetails';

export const queryCareCalloutDetails = (
    context: QueryFunctionContext<VP.Care.Queries.CareCalloutService.CareCalloutQueryKey>,
): Promise<VP.Care.CareData | null> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        locale,
    }] = queryKey;

    return careCalloutService.getCareCalloutDetails(locale);
};
