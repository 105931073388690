import { memo } from 'react';

import { McpImage, McpImageProps } from 'client/components/common/McpImage';

export interface PreviewImageProps extends Omit<McpImageProps, 'width'> {
    style?: React.CSSProperties;
    maxPreviewHeight: [number, string | false];
    bypassedApproval?: Gallery.Models.Url.ValidParsedQsValue<boolean>;
}

export const PreviewImage = memo((props: PreviewImageProps): JSX.Element => {
    const {
        previewWidth,
        previewsUrls,
        style = {},
        maxPreviewHeight,
        bypassedApproval,
        ...rest
    } = props;
    const height = maxPreviewHeight[1] || maxPreviewHeight[0];

    return (
        <div
            className="tile-preview"
            style={{
                height,
                ...style,
            }}
        >
            <McpImage
                bypassedApproval={bypassedApproval}
                className="tile-preview-image"
                maxPreviewHeight={maxPreviewHeight}
                srcInfo={previewsUrls}
                width={previewWidth}
                {...rest}
            />
        </div>
    );
});

PreviewImage.displayName = 'PreviewImage';
