import { memo } from 'react';
import classnames from 'classnames';
import {
    ColorSwatches,
    ColorSwatchesMore,
    SelectionSet,
} from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { ShowMoreButton } from 'client/components/common/ShowMoreButton';
import { hash } from 'client/utils/hash';
import { useColorSwatchesParameters } from '~/client/components/common/ColorSwatchPicker/hooks/useColorSwatchesParameters';
import { ColorSwatchWrapper } from '~/client/components/common/ColorSwatchPicker/components/ColorSwatchWrapper';
import { ColorSwatchPickerProps } from '~/client/components/common/ColorSwatchPicker/interface';
import { getIsColorSwatchesSimilarColors } from '~/client/utils/getColorSwatchColors';

function getRegionIdBasedOnColors(colorSwatches: Gallery.ContentQuery.ColorSwatch[]): string {
    return hash(colorSwatches.map((colorSwatch) => colorSwatch.designId).join(''));
}

export const ColorSwatchPicker = memo((props: ColorSwatchPickerProps): JSX.Element => {
    const {
        ariaLabelledBy,
        collapse = false,
        colorSwatches,
        colorSwatchesProps = {},
        entityId = null,
        onLabelClick,
        onColorSwatchChange,
        selectedDesignId,
        locale,
        title,
        shouldShowMerchModules,
    } = props;

    const localize = useTranslations();

    // If props.entityId is not provided, hash the available color strings.
    const colorRegionId = `colors-${entityId || getRegionIdBasedOnColors(colorSwatches)}`;

    const {
        shouldShowMore,
        visibleSwatches,
        shouldShowMoreMobile,
        shouldHideTwoLastItems,
        shouldHideLastItem,
        colorSwatchesLength,
        setShouldShowMore,
        mobileMaxColorSwatchesToShow,
        shouldShowMoreButton,
    } = useColorSwatchesParameters(collapse, colorSwatches, shouldShowMerchModules);

    const isColorSwatchColorsSimilar = getIsColorSwatchesSimilarColors(visibleSwatches);

    const toggleShowMore = (): void => setShouldShowMore(!shouldShowMore);

    const onColorSwatchChangeWrapper = (selectedValue: string | number | null): void => {
        const stringifiedValue = selectedValue?.toString() || '';

        onColorSwatchChange(stringifiedValue);
    };

    return (
        <>
            {(colorSwatches.length <= 1) && null}
            {(colorSwatches.length > 1) && (
                <div
                    aria-labelledby={ariaLabelledBy}
                    className={classnames(
                        'color-swatch-picker',
                        {
                            'hide-last-child': shouldShowMoreMobile,
                            'merch-color-swatch-picker': shouldShowMerchModules,
                            'hide-two-last-items': shouldHideTwoLastItems,
                            'hide-last-item': shouldHideLastItem,
                        },
                    )}
                >
                    { title && title()}
                    <SelectionSet
                        aria-label={localize('ColorSwatchGroupLabel')}
                        selectedValue={selectedDesignId}
                        variant="single-select"
                        onSelectedValueChange={onColorSwatchChangeWrapper}
                    >
                        <ColorSwatches id={colorRegionId} {...colorSwatchesProps}>
                            {visibleSwatches.map((colorSwatch) => (
                                <ColorSwatchWrapper
                                    colorSwatch={colorSwatch}
                                    isColorSwatchColorsSimilar={isColorSwatchColorsSimilar}
                                    key={colorSwatch.designId}
                                    locale={locale}
                                />
                            ))}
                        </ColorSwatches>
                    </SelectionSet>
                    {shouldShowMoreMobile && (
                        <ColorSwatchesMore className="show-more-badge mobile" onClick={onLabelClick}>
                            {`+${colorSwatches.length - mobileMaxColorSwatchesToShow}`}
                        </ColorSwatchesMore>
                    )}
                    {shouldShowMoreButton && (
                        <ShowMoreButton
                            active={shouldShowMore}
                            aria-controls={colorRegionId}
                            aria-expanded={shouldShowMore}
                            aria-label={localize('ColorSwatchShowMoreAriaLabel')}
                            className={classnames('tablet desktop', { 'disable-click': shouldShowMerchModules })}
                            shoulHideMoreArrowIcon={shouldShowMerchModules}
                            onClick={toggleShowMore}
                        >
                            {`+ ${colorSwatchesLength}`}
                        </ShowMoreButton>
                    )}
                </div>
            )}
        </>
    );
});

ColorSwatchPicker.displayName = 'ColorSwatchPicker';
